#navbar {
  width: 100vw;
  background-color: #334257;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#navLeft {
  width: 40%;
  display: flex;
  flex-direction: row;
  padding-left: 5%;
}

#navLeft h1 {
  color: white;
  font-size: 2.2vw;
}

#navLeft #logo {
  padding-left: 7%;
}

#navRight {
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#navRight p {
  color: white;
  font-size: 1.2vw;
}

#navRight p:hover {
  text-decoration: underline;
  cursor: pointer;
}

#menu {
  display: flex;
  flex-direction: column;
  width: 100vw;
  display: none;
}

#menu div {
  width: 30px;
  height: 5px;
  background-color: #334257;
  margin-right: 7%;
}

#menuItems {
  width: 100vw;
  background-color: #334257;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: none;
}

#menuItems p {
  color: white;
  font-size: 2vh;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
  #navbar {
    display: none;
  }

  #menu {
    display: flex;
  }
}
