#signupContainer{
    width:100vw;
    height:100vh;
    background-color: #f8f7f2;
}

#signupForm{
    padding:3%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top:5%;
}

#signupForm h1{
    font-size:3vw;
}

#signupForm input{
    padding:1%;
    width:20%;
    margin-top:1%;
    border:none;
    background-color: #cececc;
    font-size:1vw;
    font-weight:600;
}

#signupForm p{
    font-size:1.3vw;
    display:inline;
}

#signupForm p p{
    color:#548CA8;
    font-weight:700;
}


#signupForm p p:hover{
    text-decoration: underline;
    cursor:pointer;
}

#signupForm button{
    padding:1%;
    width:12%;
    border:none;
    background-color: #548CA8;
    color:white;
    font-weight:600;
    font-size:1vw;
    text-decoration: none;
}

#signupForm button:hover{
    cursor:pointer;
    background-color: #476072;

}

@media screen  and (min-width : 0px) and (max-width:1000px){
    
    #signupForm{
        width:100%;
        padding-top:20%;
    }

    #signupForm h1{
        font-size:4vh;
    }

    #signupForm input{
        padding:2.5%;
        width:45%;
        font-size:1.2vh;
    }

    #signupForm p{
        font-size:2vh;
    }

    #signupForm button{
        width:30%;
        padding:2%;
        font-size:1.5vh;
    }

}