#navbar_phyc{
    width:100vw;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1%;
    border-bottom: 1px solid rgb(224, 224, 224);
}

#nav_left_phyc{
    width:20%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#nav_left_phyc #menu_phyc{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#menu_phyc div{
    width:40px;
    height:5px;
    background-color: black;
    margin-top: 10%;
    border-radius: 25px;
}

#nav_left_phyc #patient_bar_phyc{
    width:60%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border:1px solid rgb(224, 224, 224);
    border-radius: 25px;
    padding-left: 2%;
    padding-right: 2%;
}

#patient_bar_phyc img{
    width:15%;
}

#patient_bar_phyc img:nth-of-type(2){
    width:8%;
}

#navbar_phyc #nav_right_phyc{
    display:flex;
    width:10%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 3%;
}

#nav_right_phyc img{
    width:25%;
}

