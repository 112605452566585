#hero-ref-doctor-container{
    width:100vw;
    height:100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

}

#hero-ref-doctor-form{
    padding:1%;
    width:80%;
    display: flex;
    flex-wrap: wrap;
}


#hero-ref-doctor-form input {
    padding: 1%;
}

#hero-ref-doctor-container button {
    background-color: blue;
    color:white;
    padding: 1%;
    width:20%;
    font-size: 1vw;
    font-weight: 600;
    margin-bottom: 2%;
}