#viewgtcContainer{
    width:100vw;
    height:100vh;
    background-color: #f8f7f2;
}

#gtcList{
    padding:3%;
}

#gtcList h1{
    font-size:3vw;
    font-weight:700;
}

#gtcList p{
    font-size:2vw;
}



#gtcNames div{
    display:flex;
    flex-direction: row;
    align-items: center;
}

#gtcNames div p{
    font-size:1.3vw;
    margin-left: 2%;
    margin-right: 2%;
}

#gtcNames div img{
    width:2%;
    height:1%;
}

#gtcNames div img:hover{
    cursor:pointer;
}


#gtcDetail{
    background-color: #548CA8;
    padding:4%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:40%;
    position: absolute;
    margin-left: 30%;
    margin-top:5%;
    display:none;
}

#gtcDetail p{
    font-size:1.3vw;
    color:white;
}

#gtcDetail button{
    padding:2%;
    width:20%;
    border:none;
    background-color: red;
    color:white;
    font-weight:700;
    font-size:1vw;
    text-decoration: none;
}


#gtcDetail button:hover{
    cursor:pointer;
    background-color: rgb(153, 14, 14);
}

#gtcConfirmation{
    background-color: wheat;
    padding:3%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:40%;
    position: absolute;
    margin-left: 30%;
    margin-top:10%;
    display:none;
}

#gtcConfirmation p{
    font-size:1.3vw;
}

#gtcConfirmation #choice button{
    padding:2%;
    width:20%;
    border:none;
    background-color: red;
    color:white;
    font-weight:700;
    font-size:1vw;
    margin-left:5%;
}

#gtcConfirmation #choice{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
}

#gtcConfirmation #choice button:nth-of-type(2){
    background-color: #296357;
}

#gtcConfirmation #choice button:hover{
    cursor:pointer;
}


@media screen  and (min-width:0px) and (max-width:1000px){

    #gtcConfirmation{
        width:90%;
        margin-left:2.5%;
        margin-top:25vh;
    }

    #gtcConfirmation p{
        font-size:2vh;
    }

    #gtcConfirmation #choice button{
        font-size:1.2vh;
    }

    #gtcDetail{
        width:80%;
        margin-left:5%;
        margin-top:40%;
    }

    #gtcDetail p{
        font-size:2vh;
    }

    #gtcDetail button{
        padding:4%;
        width:30%;
        margin-top:2%;
    }


    #gtcList{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20%;
    }

    #gtcList h1{
        font-size:4vh;
    }

    #gtcList p{
        font-size:3vh;
    }

    #gtcNames{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:100%;
    }

    #gtcNames div{
        justify-content: center;
        width: 100%;
    }

    #gtcNames div p{
        font-size : 2vh;
    }

    #gtcNames div img{
        width:5%;
    }
    
}