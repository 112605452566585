#addgtcContainer{
    width:100vw;
    height:100vh;
    background-color: #f8f7f2;
}

#addgtcForm{
    display:flex;
    flex-direction: column;
    padding:2%;
    height:50%;
    padding-left: 3%;
}

#addgtcForm h1{
    font-size:3vw;
    font-weight:700;
}

#addgtcForm p{
    font-size:2vw;
    font-weight:500;
    margin-top: -1.5%;
}

#addgtcForm input{
    width:20%;
    border:none;
    padding:0.8%;
    background-color: #cececc;
    border-radius: 1%;
    margin-top:1%;
}

#addgtcForm button{
    width:10%;
    margin-top:2%;
    padding:0.5%;
    border:none;
    background-color: #548CA8;
    color: white;
    font-weight:500;
    font-size:1vw;
    text-decoration: none;
}

#addgtcForm  button:hover{
    cursor:pointer;
    background-color: #476072;
}






@media screen and (min-width:0px) and (max-width:500px){
    
    #addgtcForm{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:100%;
        padding:0;
    }

    #addgtcForm h1{
        font-size:5vh;
    }

    #addgtcForm p{
        font-size:3vh;
        font-weight:500;
        margin-top: -5%;
    }

    #addgtcForm input{
        width:80%;
        padding:2%;
        background-color: #cececc;
        margin-top: 5%;
    }
    
    #addgtcForm button{
        width:40%;
        padding:3%;
        font-weight:500;
        font-size:1.8vh;
        margin-top: 5%;
    }

    #gtcFile{
        display:flex;
        flex-direction: row;
        justify-content: center;
    }
    
    
    
}


@media screen and (min-width:500px) and (max-width:1000px){
    
    #addgtcForm{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:100%;
        padding:0;
    }

    #addgtcForm h1{
        font-size:7vh;
    }

    #addgtcForm p{
        font-size:4vh;
        font-weight:500;
        margin-top: -5%;
    }

    #addgtcForm input{
        width:60%;
        padding:3%;
        background-color: #cececc;
        margin-top: 5%;
    }
    
    #addgtcForm button{
        width:40%;
        padding:3%;
        font-weight:500;
        font-size:1.8vh;
        margin-top: 5%;
    }

    #gtcFile{
        display:flex;
        flex-direction: row;
        justify-content: center;
    }
    
    
    
}