body{
    overflow-y: visible;
}

html{
    overflow-y: visible;
}

#homeContainer{
    width:100vw;
    height:100vh;
    background-color:#f8f7f2 ;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

#homeContent{
    width:60%;
    padding-left:3%;
    padding-right: 0;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

#homeContainer img{
    width:25%;
}

#homeContent h1{
    font-size:3vw;
}

#homeContent p:nth-of-type(1){
    font-size:1.5vw;
    margin-top:-1.1%;
    font-weight:600;
}

#homeContent p:nth-of-type(2){
    width:70%;
    font-size:1.5vw;
    text-align: justify;
    font-weight:400;
    margin-top: -1.1%;
}


#homeContent #homeButtons button{
    width: 70%;
    padding:5%;
    border:none;
    background-color: #548CA8;
    color:white;
    font-size:1.2vw;
    font-weight:600;
    margin-top:5%;
    border-radius: 25px;
    text-decoration: none;
}
#homebtn{
    width:100%;
    display:flex;
    flex-direction: row;
}
#homeContent #homeButtons{
    display:flex;
    flex-direction: row;
    width:40%;
}
#homeContent #homeButtons button:hover{
    cursor:pointer;
    background-color: #476072;

}

#homeContent #homeButtons button:nth-of-type(2){
    margin-left:5%;
}


@media screen and (min-width:0px) and (max-width:1000px){

    #homeContent{
        width:100%;
        align-items: center;
        padding:0;
        padding-top:10%;
    }

    #homeContainer img{
        width:50%;
    }

 

    #homeContent h1{
        font-size: 3.5vh;
    }

    #homeContent p:nth-of-type(1){
        font-size:1.8vh;
    }
    
    #homeContent p:nth-of-type(2){
        font-size:1.8vh;
        width:80%;
    }

    #homeContent #homeButtons{
        display:flex;
        flex-direction: column;
        width:100%;
        align-items: center;
    }

    #homeContent #homeButtons button{
        width:25%;
        padding:3%;
        font-size:1.3vh;
    }

    #homeContent #homeButtons button:nth-of-type(2){
        margin-left:0%;
        margin-top:5%;
    }

    #homebtn{
        width:100%;
        display:flex;
        flex-direction: row;
        justify-content: center;
    }

}