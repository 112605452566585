body{
    margin:0;
    overflow-x: hidden;
}

html{
    margin:0;
    overflow-x: hidden;
}

#adminNav{
    width:"100vw";
    background-color: #334257;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding:1.5%;
}

#adminNavLeft{
    width:50%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#adminNavRight{
    width:30%;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

#adminNavLeft p{
    color:white;
    font-size:1.1vw;
    font-weight : 400;
}

#adminNavRight p{
    color:white;
    font-size:1.1vw;
    font-weight : 400;
}

#adminNavRight p:hover{
    text-decoration: underline;
    cursor:pointer;
}

#adminNavLeft p:hover{
    text-decoration: underline;
    cursor:pointer;
}

#adminNavMobile{
    width:100vw;
    background-color: #296357;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(1000px);
    position:absolute;
    display: none;

}

#adminNavMobile p{
    color:white;
    font-size:2vh;
}

#menu div{
    width:30px;
    height:5px;
    background-color: #296357;
    margin-top: 1%;
}

#menu{
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding:3%;
    background-color: #f8f7f2;
    display:none;
}



@media screen and (min-width : 0px) and (max-width : 1000px){

    #adminNav{
        display:none;
    }

    #menu{
        display:flex;
    }

    #adminNavMobile{
        display:flex;
    }

}
