#chatContainer{
    width:50vw;
    height:50vh;
    background-color:#dddedf ;
    margin-right:5%;
    border-radius: 25px;
    overflow-y: scroll;
}


#chatInput{
    width:50vw;
    background-color: #334257;
    margin-top:2%;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 25px;
}

#chatInput input{
    padding:2.5%;
    width:70%;
    border:none;
    background-color: #334257;
    color:white;
    border-radius:25px;
    font-size:1vw;
}

#chatInput input:focus{
    border-color: #1d423b00;
    outline: none;
}

#chatInput input::placeholder{
    color:white;
    font-size:1vw;

}

#chatInput button{
    padding:1%;
    width:10%;
    border-radius: 25px;
    color:white;
    background-color: #548CA8;
    text-decoration: none;
    font-weight: 600;
}

#chatInput button:hover{
    cursor:pointer;
    background-color: #476072;
}


@media screen and (min-width:0px) and (max-width:1000px){
    
    #chatContainer{
        width:95vw;
        height:40vh;
        background-color:#dddedf ;
        margin-right:5%;
        border-radius: 10px;
        overflow-y: scroll;
        margin-top:15%;
    }

    #chatInput{
        width:90vw;
        background-color: #296357;
        margin-top:2%;
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        border-radius: 10px;
        padding:2%;
    }

    #chatInput button{
        padding:1%;
        width:20%;
        border-radius: 25px;
        border:1px solid white;
        color:white;
        font-size:1.2vh;
        background-color: #296357;
    }

    #chatInput input{

        font-size:1.3vh;
    }

    #chatInput input::placeholder{
        color:white;
        font-size:1.5vh;
    
    }


    
    

}
