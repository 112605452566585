#chatboxContainer{
    width:100vw;
    height:100vh;
    background-color: #f8f7f2;
}

#chatboxContent{
    padding:3%;
    display:"flex";
    flex-direction: column;

}

#chatboxContent #chatHeading p{
    font-size:1.5vw;
    margin-top:-1.1%;
}


#chatboxContent #chatHeading h1{
    font-size:3vw;
}

#chatboxContent #chatboxDiv{
    display:flex;
    flex-direction: row;
    width:95vw;
    align-items: flex-start;
}

#chatboxContent #chatboxDiv #options {
    display:flex;
    flex-direction: column;
    width:50%;
}


#chatboxContent #chatboxDiv #options select {
    padding:2%;
    width:70%;
    border:none;
    background-color: #cececc;
    color:#4b494a;
    margin-top:2%;
    cursor:pointer;
}

#load{
    width:20%;
    padding:2%;
    border:none;
    background-color: #548CA8;
    color:white;
    font-size:0.8vw;
    margin-top:3%;
    border-radius: 10px;
    text-decoration: none;
}

#load:hover{
    background-color: #476072;
}


@media screen and (min-width:0px) and (max-width:1000px){
    #chatboxContent #chatHeading p{
        font-size:2vh;
        margin-top:-1.1%;
        width:95%;
        text-align: center;
    }
    
    #chatboxContent #chatHeading h1{
        font-size:3vh;
    }

    #chatboxContent #chatHeading{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top:10%;
    }

    #chatboxContent #chatboxDiv{
        display:flex;
        flex-direction: column;
        width:100vw;
        align-items: center;

    }

    #chatboxContent #chatboxDiv #options {
        display:flex;
        flex-direction: column;
        width:100%;
        align-items: center;
        margin-top:10%;
    }

    #chatboxContent #chatboxDiv #options select {
        padding:2%;
        width:80%;
        border:none;
        background-color: #cececc;
        color:#4b494a;
        margin-top:2%;
        cursor:pointer;
        font-size:2vh;
    }

    #chatboxContent{
        padding:0%;
    }

    #load{
        width:20%;
        font-size:1vh;

    }
    
}
