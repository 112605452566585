#prompt_container{
    width:100vw;
    height:100vh;
    background-color: white;
}

#prompt_menu{
    width:100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

#prompt_menu div{
    display:flex;
    flex-direction: column;
    width:20%;
}

#prompt_menu div select{
    padding:2%;
    width:70%;
}

#input_area{
    width:100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 5%;
}

#input_area div{
    width:25%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    padding:1%;
    border-radius: 25px;
    padding-left: 2%;
}

#input_area p{
    line-height: 150%;
    text-align: justify;
}

#input_area div input{
    border:none;
    width:80%;
    padding-bottom: 80%;
    padding-top: 3%;
    padding-left: 3%;
    border-radius: 25px;
    background-color: #E2E8F0;
}

#input_area div button{
    margin-left: 35%;
    margin-top: 5%;
    padding:2%;
    border:none;
    width:20%;
    border-radius: 10px;
    background-color: #6748B6;
    color:white;
    cursor: pointer;
}



#stored_results{
    width:100vw;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
}
#stored_results li{
    margin-top: 2vh;
    padding: 2%;
    background-color: #E6FFFA;;
    border-radius: 15px;
}

#stored_results li p{
    width:80%;
    line-height: 150%;
}
#input_area div button:hover{
    background-color: #8369c3;
}

#input_area div:nth-of-type(1)
{
    background-color:#FEFCBF ;
}

#input_area div:nth-of-type(2)
{
    background-color:rgb(225, 225, 225) ;
}

#input_area div:nth-of-type(3)
{
    background-color:#E6FFFA ;
}

#input_area div:nth-of-type(3) p
{
    background-color:#E6FFFA ;
}


