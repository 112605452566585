#loginContainer {
  width: 100vw;
  height: 88vh;
  background-color: #f8f7f2;
}

#loginForm {
  padding: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
}

#loginForm h1 {
  font-size: 3vw;
}

#loginForm input {
  padding: 1%;
  width: 20%;
  margin-top: 1%;
  border: none;
  background-color: #cececc;
  font-size: 1vw;
  font-weight: 600;
}
input::after{
  background-color: #476072;
}

#loginForm p {
  font-size: 1.3vw;
  display: inline;
}

#loginForm p p {
  color: #548CA8;
  font-weight: 700;
}

#loginForm p p:hover {
  text-decoration: underline;
  cursor: pointer;
}

#loginForm button {
  padding: 1%;
  width: 12%;
  border: none;
  background-color: #548CA8;
  color: white;
  font-weight: 600;
  font-size: 1vw;
  text-decoration: none;
}

#loginForm button:hover {
  cursor: pointer;
  background-color: #476072;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
  #loginForm {
    width: 100%;
    padding-top: 20%;
  }

  #loginForm h1 {
    font-size: 4vh;
  }

  #loginForm input {
    padding: 2.5%;
    width: 45%;
    font-size: 1.2vh;
  }

  #loginForm p {
    font-size: 2vh;
  }

  #loginForm button {
    width: 30%;
    padding: 2%;
    font-size: 1.5vh;
  }
}
