#bubble{
    background-color: #476072;
    padding:1%;
    padding-right: 5%;
    width:80%;
    border-radius: 25px;
    margin-top:2%;
    margin-left:2%;
}

#bubble p{
    color:white;
    font-size:1vw;
    font-weight:600;
    margin-left:4%;
    text-align: justify;

}

#bubbleUser{
    background-color: #334257;
    padding:0.1%;
    width:40%;
    border-radius: 25px;
    margin-top:2%;
    margin-left:58%;

    
}

#bubbleUser p{
    color:white;
    font-size:1vw;
    font-weight:600;
    margin-left:4%;
    text-align: justify;
}

@media screen and (min-width:0px) and (max-width:1000px){

    #bubble{
        width:45%;
    }
    
    #bubble p{
        font-size:1.2vh;   
    }

    #bubbleUser{
        width:45%;
    }
    
    #bubbleUser p{
        font-size:1.2vh;   
    }
    
}