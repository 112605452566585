#patient_info{
    width:80vw;
    height:20vh;
    padding-left:3%;
    padding-top:1%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3%;
}

#patient_info #patient_image{
    background-image: url("../assets/p1.png");
    width:20%;
    height:90%;
    background-size: cover;
    border-radius: 25px;
}

#patient_info #details{
    width:55%;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

#patient_info #details p:nth-of-type(1)
{
    font-weight: 700;
    font-size: 1.5vw;
}

#patient_info #details p{
    font-weight: 400;
    margin-top: 0.5%;
}

#patient_info #contact{
    width:15%;
    display:flex;
    flex-direction: column;
}

#patient_info #contact button{
    background-color: #38b2ac;
    color:white;
    border:none;
    padding:6%;
    width:90%;
    margin-top: 8%;
    border-radius: 25px;
    cursor:pointer;
}

#patient_info #contact button:hover{
    background-color: #47e2da;

}

#choose{
    width:100vw;
    display:flex;
    flex-direction: row;
    padding-left:3%;
    margin-top: 1%;
    align-items: center;
}

#choose h2{
    font-weight: 400;
}

#choose button{
    width:6%;
    padding:0.5%;
    border-radius: 25px;
    background-color: #38b2ac;
    color:white;
    border:none;
    cursor:pointer;
    margin-left: 2%;

}

#choose button:hover{
    background-color: #47e2da;
}


#note_type{
    width:100vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    padding:3%;
    flex-wrap: wrap;
}

#note_type .type_div{
    width:20%;
    height:30vh;
    margin-left: 3%;
    margin-top: 3%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 2px 2px 16px 0px rgba(204, 252, 252, 0.75);
    -webkit-box-shadow: 2px 2px 16px 0px rgba(186, 247, 247, 0.75);
    -moz-box-shadow: 2px 2px 16px 0px rgba(210, 248, 248, 0.75);
    border-radius: 25px;
    cursor:pointer;
}

#note_type .type_div:nth-of-type(1), #note_type .type_div:nth-of-type(5)
{
    margin-left: 0%;
}


#note_type .type_div #top{
    width:100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}



#top p:nth-of-type(2)
{
    color:#38b2ac;
    font-weight: 600;
    cursor:pointer;
}

#scores .score_divs #info_p
{
    width:60%;
    text-align: center;
}
