#patient_info{
    width:80vw;
    height:20vh;
    padding-left:3%;
    padding-top:1%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1%;
}

#patient_info #patient_image{
    background-image: url("../assets/p1.png");
    width:20%;
    height:90%;
    background-size: cover;
    border-radius: 25px;
}

#patient_info #details{
    width:55%;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

#patient_info #details p:nth-of-type(1)
{
    font-weight: 700;
    font-size: 1.5vw;
}

#patient_info #details p{
    font-weight: 400;
    margin-top: 0.5%;
}

#patient_info #contact{
    width:15%;
    display:flex;
    flex-direction: column;
}

#patient_info #contact button{
    background-color: #38b2ac;
    color:white;
    border:none;
    padding:6%;
    width:90%;
    margin-top: 8%;
    border-radius: 25px;
    cursor:pointer;
}

#patient_info #contact button:hover{
    background-color: #47e2da;
}

#results{
    width:60%;
    margin-left: 3%;
    padding:2%;
    padding-top: 1%;
    border-radius: 25px;
    margin-top: 3%;
    background-color: #e9f3fa;
}

#results p{
    text-align: justify;
}