#hero-rec-container{
    width:100vw;
    height:100vh;
    display:flex;
    flex-direction: column;
    align-items:center ;
}


#hero-rec-banner{
    width:100%;
    background-color: #007AFF;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#hero-rec-banner p{
    font-size: 1.5vw;
    color:white;
    font-weight: 600;
}

#hero-patient-card{
    width:70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0px 9px 14px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 9px 14px -2px rgba(8, 137, 135, 0.75);
    -moz-box-shadow: 0px 9px 14px -2px rgba(0,0,0,0.75);
    margin-top: 3%;
    border-radius: 25px;
    padding:2%;
}

#patient-card-subsection1{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

#patient-card-buttons{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:30%;
}

#patient-card-buttons button{
    padding:3%;
    background-color: rgb(9, 169, 158);
    color:white;
    border-radius: 25px;
    width:30%;
    margin-top: 2%;
}

#hero-rec-stats{
    width:90%;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 3%;
}


.stats-box{
    width:21%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 9px 14px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 9px 14px -2px rgba(12, 169, 164, 0.75);
    -moz-box-shadow: 0px 9px 14px -2px rgba(0,0,0,0.75);
    border-radius: 25px;
}

#hero-recommendations{
    width:70%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 3%;
}

#hero-recommendations p{
    font-size: 2vw;
    font-weight: 600;
}

#hero-rec-card{
    cursor: pointer;
    width:80%;
    display:flex;
    flex-direction: row;
    align-items: center;
    border-radius: 25px;
    padding:2%;
    box-shadow: 0px 9px 14px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 9px 14px -2px rgba(12, 169, 164, 0.75);
    -moz-box-shadow: 0px 9px 14px -2px rgba(0,0,0,0.75);
    margin-top: 3%;

}

#hero-rec-card-subsection{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 3%;
}

.subsection-rows{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width:100%;
}

.subsection-rows p{
    font-weight: 400 !important;
    font-size: 1.2vw !important;
    margin: 0;
}

.icons{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width:8%;
    cursor: pointer;
}

#stars{
    width:10%;
    margin-top: 1%;
}

#hero-subcontainer{
    width:100vw;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
}

#hero-filters{
    width:20%;
    display:flex;
    flex-direction: column;
    align-items: flex-start; 
    padding:3%;
    padding-top: 5%;

}

#hero-filters p{
    font-size: 1.2vw;
}

#recommendation-type{
    width:100%;
    display:flex;
    flex-direction: row;
    align-items: center;
}

#recommendation-type button{
    color:white;
    font-size: 1.2vw;
    background-color: rgb(9, 169, 158);
    padding:2%;
    font-weight: 600;
    cursor: pointer;
}



