#hero-ref-user-container{
    width:100vw;
    height:100vh;
}

#refferal-form-user{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#refferal-form-user input{
    padding:1%;
    width:30%;
    margin-top: 2%;
    font-size: 1vw;
}

#refferal-form-user textarea{
    padding:1%;
    width:30%;
    font-family  :'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    height:20vh;
    margin-top: 2%;
}

#hero-recommendations-ref{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2%;
}

#hero-recommendations-ref p:nth-of-type(1)
{
    font-size: 2vw;
    font-weight: 600;
}

#refferal-form-user button{
    width:32.4%;
    font-size: 1vw;
    font-weight: 600;
    color:white;
    background-color: blue;
    padding:1%;
    margin-top: 2%;
}