#phyc_container
{
    width:100vw;
    height:100vh;
}

#phyc_header{
    width:80%;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 3%;
    margin-left: 10%;
}

#phyc_details{
    width:90%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#doctor_details_phyc{
    width:70%;
    display:flex;
    flex-direction: row;
    align-items: center;
    border-radius: 25px;
    padding:2%;
    box-shadow: 2px 5px 6px 0px rgba(189,189,189,0.75);
    -webkit-box-shadow: 2px 5px 6px 0px rgba(189,189,189,0.75);
    -moz-box-shadow: 2px 5px 6px 0px rgba(189,189,189,0.75);
}

#doctor_details_phyc img{
    width:40%;
}

#patient_details_phyc{
    width:70%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 25px;
    padding:2%;
    box-shadow: 2px 5px 6px 0px rgba(189,189,189,0.75);
    -webkit-box-shadow: 2px 5px 6px 0px rgba(189,189,189,0.75);
    -moz-box-shadow: 2px 5px 6px 0px rgba(189,189,189,0.75);
}

#patient_details_phyc img{
    width:20%;
}

#patient_details_phyc #patient_buttons_phyc{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:30%;
}

#patient_details_phyc #patient_info_phyc p{
    margin:0;
    margin-top: 3%;
}

#patient_buttons_phyc button{
    color:white;
    background-color: #38B2AC;
    margin-top: 2%;
    border:none;
    padding:3%;
    width:60%;
    border-radius: 25px;
}

#patient_buttons_phyc button:hover{

    background-color: #136e69;

}


#phyc_types{
    display:flex;
    width:60%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#phyc_types p{
    background-color: #d9e9fd;
    color:rgb(110, 110, 110);
    padding:1%;
    width:35%;
    text-align: center;
    padding-top:2%;
    padding-bottom:2%;
    border-radius: 25px;
    margin:0;
    margin-top: 2%;
    font-weight: 400;
}

#template{
    width:40%;
    display:flex;
    background-color: #FEFCBF;
    flex-direction: column;
    justify-content: center;
    margin-left: 15%;
    padding:2%;
    padding-top: 1%;
    border-radius: 25px;
    margin-bottom: 5%;
}

#template #template_choice{
    display:flex;
    flex-direction: row;
    align-items: center;
}

#template_choice select{
    padding:1%;
    border:none;
    width:50%;
}


#template_choice button{
    margin-left: 5%;
    padding:1%;
    border:none;
    width:50%;
    background-color: #38B2AC;
    color:white;
    border-radius: 25px;
}

#template_choice button:hover{
    background-color: #136e69;
}

#template p:nth-of-type(2){
    background-color: aliceblue;
    padding: 3%;
    padding-bottom: 10%;
}

#results_phyc{
    width:40%;
    display:flex;
    background-color:#d9e9fd;
    flex-direction: column;
    justify-content: center;
    margin-left: 15%;
    padding:2%;
    padding-top: 1%;
    border-radius: 25px;
    margin-bottom: 3%;
}
#results_phyc p{
    text-align: justify;
    line-height: 150%;
    margin-top: 0%;
}

#generate_report{
    display:flex;
    width:10%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color:#d9e9fd;
    border-radius: 15px;
    margin-left: 15%;
    margin-bottom: 2%;
    cursor:pointer;
}

#generate_report p{
    color:#5d5d5d;
    font-weight: 600;
}

#generate_report img{
    width:20%;
}

#controls{
    display:flex;
    width:42%;
    flex-direction: column;
    justify-content: center;
    padding:1%;
    border-radius: 15px;
    margin-left: 15%;
    margin-bottom: 2%;
    box-shadow: 2px 5px 6px 0px rgba(189,189,189,0.75);
    -webkit-box-shadow: 2px 5px 6px 0px rgba(189,189,189,0.75);
    -moz-box-shadow: 2px 5px 6px 0px rgba(189,189,189,0.75);
}

#controls #panel_phyc {
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

#panel_phyc div{
    width:30%;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

#panel_phyc div img{
    width:20%;
    height:20%;
}

#panel_phyc div p{
    background-color: #b38dff;
    color:white;
    padding:5%;
    width:50%;
    text-align: center;
    border-radius: 25px;
}

#panel_phyc div p:hover{
    background-color: #774bcf;
    cursor:pointer;
}

#controls input{
    border:1px solid rgb(2, 180, 157);
    padding:2%;
    border-radius: 15px;
    color:#531ebe;
    font-weight: 600;
}

button{
    cursor:pointer;
}

#saveButton{
    background-color: #b38dff;
    color:white;
    padding:1%;
    width:15%;
    text-align: center;
    border: none;
    border-radius: 25px;
    margin-top: 1vh;
}

#saveButton:hover{
    background-color: #774bcf;
    cursor:pointer;
}

#editableText{
    padding: 3%;
    background-color: #e1e9e8;
    height:15vh;
    border: none;
    resize: none;
    overflow: 'hidden';
    line-height: 200%;
}

button{
    text-decoration: none;
}