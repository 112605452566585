#hero-user-container{
    width:100vw;
    height:100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#hero-user-top-bar{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #007AFF;
}

#hero-user-top-bar p{
    color:white;
    font-size: 1.5vw;
    font-weight: 600;
}

#hero-user-input{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/hero-user-main.png");
    background-size: cover;
    opacity: 0.9;
    padding-bottom: 5%;
}

#hero-user-input p:nth-of-type(1){
    font-size: 2.5vw;
    color:black;
    font-weight: 600;
    margin-bottom: 0;
}

#hero-user-input p:nth-of-type(2){
    font-size: 2vw;
    color:#282e38;
    font-weight: 600;
    font-style: italic;
}

#hero-user-input-row{
    width:40%;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:0% 2%;
    padding-right: 0%;
    padding-left: 0%;
    border-radius: 25px;
}

#hero-user-input-row .hero-user-input-row-section:nth-of-type(2)
{
    border:none;
    padding-right: 0%;
}

#hero-user-input-row .hero-user-input-row-section{
    width:35%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-right: 5px solid black;
    padding-left: 2%;
}



#hero-user-input-row .hero-user-input-row-section p{
    color: black;
    font-size: 1.3vw;
    font-weight: 400;
    width:100%;
}

#hero-user-input-row .hero-user-input-row-section input{
    border: none;
    padding:5%;
    padding-left: 0%;
    font-size: 1vw;
    width:90%;
    margin-bottom: 5%;
}

#hero-search-card{
    width:45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #E2E8F0;
    padding:2%;
    border-radius: 25px;
    padding-top: 1%;
    margin-top: 5%;
}

#hero-search-card p{
    font-size: 1vw;
}

#search-card-row1 img{
    width:40%;
}

#search-card-row1{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

#search-card-row1 p:nth-of-type(1)
{
    font-size: 1.2vw;
    font-weight: 600;
}

#search-card-row1 p{
    margin:0;
    margin-top: 2%;
}

#r1-subsection{
    display:flex;
    flex-direction: column;
    margin-left: 5%;
}
#r1-subsection p{
    font-size: 1vw;
}
#subsection-buttons{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 5%;
}

#subsection-buttons button{
    border:none;
    color:white;
    padding:2%;
    border-radius: 25px;
    width: 50%;
    font-size: 0.9vw;
    cursor:pointer;
}



#subsection-buttons button:nth-of-type(1)
{
    background-color: rgb(15, 168, 168);
    color:white;
}
