body {
  overflow-y: visible;
}

#userDetails {
  width: 100vw;
  height: 100vh;
  background-color: #f8f7f2;
}

#userHeading {
  padding: 3%;
}

#userHeading h1 {
  font-size: 2.5vw;
  font-weight: 700;
}

#userHeading p {
  font-size: 2vw;
  margin-top: -1.1%;
}

#userBar {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #296357;
  padding-left: 2.5%;
  padding-right: 2.5%;
  align-items: center;
  margin-top: 1%;
}

#userBar p {
  color: white;
}

#userBar img {
  width: 5%;
}

#userBar img:hover {
  cursor: pointer;
}

#userList {
  display: flex;
  flex-direction: column;
  padding-left: 3%;
  background-color: #f8f7f2;
}

#userConfirmation {
  background-color: wheat;
  padding: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  position: absolute;
  margin-left: 30%;
  margin-top: 10%;
  display: none;
}

#userConfirmation p {
  font-size: 1.3vw;
}

#userConfirmation #userChoice button {
  padding: 2%;
  width: 20%;
  border: none;
  background-color: red;
  color: white;
  font-weight: 700;
  font-size: 1vw;
  margin-left: 5%;
}

#userConfirmation #userChoice {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#userConfirmation #userChoice button:nth-of-type(2) {
  background-color: #296357;
}

#userConfirmation #userChoice button:hover {
  cursor: pointer;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
  #userHeading {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #userHeading h1 {
    font-size: 4vh;
  }

  #userHeading p {
    font-size: 3vh;
  }

  #userBar {
    width: 90%;
  }

  #userBar p {
    font-size: 1.5vh;
  }

  #userConfirmation {
    background-color: wheat;
    padding: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    position: absolute;
    margin-left: 7%;
    margin-top: 80%;
  }

  #userConfirmation p {
    font-size: 1.5vh;
  }

  #userConfirmation #userChoice button {
    padding: 2%;
    width: 20%;
    border: none;
    background-color: red;
    color: white;
    font-weight: 700;
    font-size: 1.4vh;
    margin-left: 5%;
  }

  #userConfirmation #userChoice {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  #userConfirmation #userChoice button:nth-of-type(2) {
    background-color: #296357;
  }

  #userConfirmation #userChoice button:hover {
    cursor: pointer;
  }
}

/* Styles for the table */
table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  padding: 12px;
  font-weight: bold;
  text-align: left;
  border-bottom: 2px solid #ddd;
}

tbody td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

tbody td:last-child {
  text-align: center;
}

.password-mask {
  cursor: pointer;
}

.password-mask::after {
  content: "*";
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  padding: 0;
  margin-left: 8px;
}
