#adminContainer{
    width : 100vw;
    height: 100vh;
    background-color: #f8f7f2;
}

#adminLoginForm{
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
}

#adminLoginForm h1{
    font-size: 4vw;
}

#adminLoginForm input{
    padding:1%;
    border:none;
    border-radius: 3%;
    margin-top:2%;
    width:20%;
    background-color: #cececc;
    font-weight: 700;
    font-size: 1vw;
}

#adminLoginForm button{
    padding:2%;
    margin-top:3%;
    border:none;
    width:40%;
    color:white;
    background-color: #548CA8;
    font-weight: 700;
    font-size: 1vw;
    text-decoration:none ;
}

#adminButton{
    width:25%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    margin-top:1%;

}

#adminLoginForm button:hover{
    cursor:pointer;
    background-color: #476072;

}

@media  screen and (min-width : 250px) and (max-width : 800px){

    #adminLoginForm h1{
        font-size: 5.5vh;
    }

    #adminLoginForm input{
        padding:4%;
        width:70%;
        font-size: 1.5vh;
    }

    #adminLoginForm button{
        padding:2.5%;
        border:none;
        width:40%;
        font-size: 2vh;
    }

    #adminLoginForm{
        padding-top: 20%;
    }

    #adminButton{
        width:70%;

    
    }
}

@media  screen and (min-width : 800px) and (max-width : 1000px){

    #adminLoginForm h1{
        font-size: 7vh;
    }

    #adminLoginForm input{
        padding:4%;
        width:50%;
        font-size: 1.5vh;
    }

    #adminLoginForm button{
        padding:2.5%;
        border:none;
        width:30%;
        font-size: 2vh;
    }

    #adminLoginForm{
        padding-top: 20%;
    }
}